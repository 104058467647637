import { GetStaticPropsResult } from 'next'
import Error from 'next/error'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanityErrorPage } from '@data/sanity/queries/types/page'
import { getPreview } from '@lib/sanity/client'
import { getErrorPage } from '@lib/sanity/page'
import { defaultLocale, Locale } from '@lib/language'
import { CustomErrorStaticPropsContext } from '@lib/routes'

import Modules from '@modules/modules'
import Layout from '@components/layout'

interface ErrorPageProps {
  locale: Locale
  page: SanityErrorPage | null
  site: SanitySiteFragment | null
}

export const NotFoundPage = ({ page, site }: ErrorPageProps) => {
  if (!page || !site) {
    return (
      <Error
        title={`"Error Page (404)" is not set in Sanity, or the page data is missing`}
        statusCode={404}
      />
    )
  }

  return (
    <Layout page={page} site={site}>
      <Modules modules={page.modules} />
    </Layout>
  )
}

export async function getStaticProps({
  locale,
  preview,
  previewData,
}: CustomErrorStaticPropsContext): Promise<
  GetStaticPropsResult<ErrorPageProps>
> {
  const errorPage = await getErrorPage(
    locale ?? defaultLocale,
    getPreview(preview, previewData)
  )

  return {
    props: {
      locale: locale ?? defaultLocale,
      page: errorPage.page,
      site: errorPage.site,
    },
    revalidate: 60,
  }
}

export default NotFoundPage
